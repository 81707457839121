import React, { useEffect } from "react";
import { useState } from "react";
import Form from "./components/Form/index";
import Confirmation from "./components/Confirmation";
import Header from "./components/Header";
import Menu from "./components/Menu";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export const StringsContext = React.createContext("strings");
export const StringsLoader = (context, key) => {
  if (context[key] && context[key].split) {
    return context[key]
      .split("\n")
      .map((item, index, arr) =>
        arr.length > 1 ? <p key={key + index}>{item}</p> : item
      );
  } else {
    return context[key] || key;
  }
};

function App() {
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(null);
  const [strings, setStrings] = useState({});
  const [aperoboxProducts, setAperoboxProducts] = useState([]);

  useEffect(() => {
    async function loadStrings() {
      const response = await fetch("/strings");
      setStrings(await response.json());
      setLoading(false);
    }
    try {
      loadStrings();
    } catch (err) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    async function loadProducts() {
      const response = await fetch("/products");
      const data = await response.json();
      setAperoboxProducts(data.aperobox);
    }
    try {
      loadProducts();
    } catch (err) {
      console.error(err);
    }
  }, []);

  return loading ? (
    <>
      <Loader
        className="loader"
        type="Oval"
        color="#FACE0B"
        height={100}
        width={100}
      />
      <div
        style={{
          color: "#FFF",
          fontSize: 0.00001,
          height: 0,
          overflow: "hidden",
        }}
      >
        <h1>Loading fonts...</h1>
        <p>Loading...</p>
      </div>
    </>
  ) : (
    <StringsContext.Provider value={strings}>
      <Header />
      {!submitted ? (
        <>
          <Menu />
          <Form
            aperoboxProducts={aperoboxProducts}
            setSubmitted={setSubmitted}
          />
        </>
      ) : (
        <Confirmation
          data={{ ...submitted, account: StringsLoader(strings, "rekening") }}
        >
          {StringsLoader(strings, "bevestiging")}
        </Confirmation>
      )}
    </StringsContext.Provider>
  );
}

export default App;
