import React, {useContext, useEffect, useRef} from "react";
import headers from "../../assets/images/banner2024.png";
import {Col, Container, Row} from "react-bootstrap";
import {StringsContext, StringsLoader as _s} from "../../App";
import feestmenu1 from "../../assets/images/feestmenu1.png";
import feestmenu2 from "../../assets/images/feestmenu2.png";
import dash from "../../assets/images/dash.png";
import hoofd from "../../assets/images/hoofd.png";
import apero from "../../assets/images/feest-ap.png";
import swiperImage1 from "../../assets/images/swiper/1.jpg";
import swiperImage2 from "../../assets/images/swiper/2.jpg";
import swiperImage3 from "../../assets/images/swiper/3.jpg";
import swiperImage4 from "../../assets/images/swiper/4.jpg";
import swiperImage5 from "../../assets/images/swiper/5.jpg";
import swiperImage6 from "../../assets/images/swiper/6.jpg";
import swiperImage7 from "../../assets/images/swiper/7.jpg";
import swiperImage8 from "../../assets/images/swiper/8.jpg";
import swiperImage9 from "../../assets/images/swiper/9.jpg";
import swiperImage10 from "../../assets/images/swiper/10.jpg";
import swiperImage11 from "../../assets/images/swiper/11.jpg";
import swiperImage12 from "../../assets/images/swiper/12.jpg";
import swiperImage13 from "../../assets/images/swiper/13.jpg";
import swiperImage14 from "../../assets/images/swiper/14.jpg";
import "swiper/swiper-bundle.min.css";
import Swiper from "swiper";
import {Autoplay} from "swiper/modules";
Swiper.use([Autoplay]);

export default function Menu() {
    const ctx = useContext(StringsContext);
    const swiperContainerRef = useRef(null);
    const ppp = (val) =>
        `€ ${val.toLocaleString ? val.toLocaleString() : val} p.p.`;
    const p = (val) => `€ ${val.toLocaleString ? val.toLocaleString() : val}`;

    useEffect(() => {
        // Initialize Swiper when component mounts
        const swiper = new Swiper(swiperContainerRef.current, {
            // Add your Swiper options here
            loop: true,
            autoplay: {
                delay: 1500, // Set delay to 2000 ms (2 seconds)
                disableOnInteraction: false, // Prevent stopping autoplay on user interaction
            },
            slidesPerView: 3,
            spaceBetween: 0,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
            },
        });
        return () => {
            swiper.destroy();
        };

    }, []);
    return (
        <div className="menu-wrapper">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="disclaimer center">
                            {_s(ctx, "intro")}
                            <p>
                                <small>
                                    <em>{_s(ctx, "intro_note")}</em>
                                </small>
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img style={{margin: "1rem 0 3rem"}} src={headers} alt=""/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="menus-wrapper">
                            <div className="menus">
                                <Row>
                                    <Col sm={12} xl={6}>
                                        <div className="menu menu-1">
                                            <img style={{margin: "auto", height: "40px"}} src={feestmenu1} alt=""/>
                                            <ul className="lvl-1">
                                                <li>
                                                    <span>{_s(ctx, "amuses")}</span>
                                                    <ul className="lvl-2">
                                                        <li>{_s(ctx, "menu_1_amuses_1")}</li>
                                                        <li>EN</li>
                                                        <li>{_s(ctx, "menu_1_amuses_2")}</li>
                                                    </ul>
                                                </li>
                                                <img style={{margin: "auto", height: "15px", marginTop: "1rem"}} src={dash} alt=""/>
                                                <li>
                                                    <span>{_s(ctx, "voorgerecht")}</span>
                                                    <ul className="lvl-2">
                                                        <li>{_s(ctx, "menu_1_voorgerecht")}</li>
                                                    </ul>
                                                </li>
                                                <img style={{margin: "auto", height: "15px", marginTop: "1rem"}} src={dash} alt=""/>
                                                <li>
                                                    <span>{_s(ctx, "hoofdgerecht")}</span>
                                                    <ul className="lvl-2">
                                                        <li>{_s(ctx, "menu_1_hoofdgerecht")}</li>
                                                    </ul>
                                                </li>
                                                <img style={{margin: "auto", height: "15px", marginTop: "1rem"}} src={dash} alt=""/>
                                                <li>
                                                    <span>{_s(ctx, "dessert")}</span>
                                                    <ul className="lvl-2">
                                                        <li>{_s(ctx, "menu_1_dessert")}</li>
                                                    </ul>
                                                </li>
                                                <h3 style={{marginTop: "1rem"}}>{ppp(_s(ctx, "menu_1_prijs"))}</h3>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col sm={12} xl={6}>
                                        <div className="menu menu-2">
                                            <img style={{margin: "auto", height: "40px"}} src={feestmenu2} alt=""/>
                                            <ul className="lvl-1">
                                                <li>
                                                    <span>{_s(ctx, "amuses")}</span>
                                                    <ul className="lvl-2">
                                                        <li>{_s(ctx, "menu_2_amuses_1")}</li>
                                                        <li>EN</li>
                                                        <li>{_s(ctx, "menu_2_amuses_2")}</li>
                                                    </ul>
                                                </li>
                                                <img style={{margin: "auto", height: "15px", marginTop: "1rem"}} src={dash} alt=""/>
                                                <li>
                                                    <span>{_s(ctx, "voorgerecht")}</span>
                                                    <ul className="lvl-2">
                                                        <li>{_s(ctx, "menu_2_voorgerecht")}</li>
                                                    </ul>
                                                </li>
                                                <img style={{margin: "auto", height: "15px", marginTop: "1rem"}} src={dash} alt=""/>
                                                <li>
                                                    <span>{_s(ctx, "hoofdgerecht")}</span>
                                                    <ul className="lvl-2">
                                                        <li>{_s(ctx, "menu_2_hoofdgerecht")}</li>
                                                    </ul>
                                                </li>
                                                <img style={{margin: "auto", height: "15px", marginTop: "1rem"}} src={dash} alt=""/>
                                                <li>
                                                    <span>{_s(ctx, "dessert")}</span>
                                                    <ul className="lvl-2">
                                                        <li>{_s(ctx, "menu_2_dessert")}</li>
                                                    </ul>
                                                </li>
                                                <h3 style={{marginTop: "1rem"}}>{ppp(_s(ctx, "menu_2_prijs"))}</h3>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{textAlign: "center"}} className="extra-info">
                                <h5>{_s(ctx, "veggie_hoofdgerecht_label")}</h5>
                                <p>{_s(ctx, "veggie_hoofdgerecht")}</p>
                            </div>

                            <div>
                                <div className="swiper-container" style={{overflow:"hidden"}} ref={swiperContainerRef}>
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <img src={swiperImage1} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage3} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage2} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage4} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage5} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage6} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage7} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage8} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage9} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage10} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage13} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage11} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage12} alt=""/>
                                        </div>
                                        <div className="swiper-slide">
                                            <img src={swiperImage14} alt=""/>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="menus">
                                <div className="menu main-platters">
                                    <img style={{margin: "auto", height: "40px", marginBottom: "1rem"}} src={hoofd} alt=""/>
                                    <br/>
                                    <ul>
                                        <li>
                                            {_s(ctx, "hoofdgerecht_1")} (
                                            {ppp(_s(ctx, "hoofdgerecht_1_prijs"))})
                                        </li>
                                        <img style={{margin: "auto", height: "10px", marginBottom: "0.5rem"}} src={dash} alt=""/>
                                        <li>
                                            {_s(ctx, "hoofdgerecht_2")} (
                                            {ppp(_s(ctx, "hoofdgerecht_2_prijs"))})
                                        </li>
                                        <img style={{margin: "auto", height: "10px", marginBottom: "0.5rem"}} src={dash} alt=""/>
                                        <li>
                                            {_s(ctx, "hoofdgerecht_3")} (
                                            {ppp(_s(ctx, "hoofdgerecht_3_prijs"))})
                                        </li>
                                        <img style={{margin: "auto", height: "10px", marginBottom: "0.5rem"}} src={dash} alt=""/>
                                        <li>
                                            {_s(ctx, "hoofdgerecht_4")} (
                                            {ppp(_s(ctx, "hoofdgerecht_4_prijs"))})
                                        </li>
                                        <img style={{margin: "auto", height: "10px", marginBottom: "0.5rem"}} src={dash} alt=""/>
                                        <li>
                                            {_s(ctx, "hoofdgerecht_5")} (
                                            {ppp(_s(ctx, "hoofdgerecht_5_prijs"))})
                                        </li>
                                        <img style={{margin: "auto", height: "10px", marginBottom: "0.5rem"}} src={dash} alt=""/>
                                        <li>
                                            {_s(ctx, "hoofdgerecht_6")} (
                                            {ppp(_s(ctx, "hoofdgerecht_6_prijs"))})
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="menus">
                                <div className="menu menu-apero">
                                    <img style={{margin: "auto", height: "40px", marginBottom: "1rem"}} src={apero} alt=""/>
                                    <br/>
                                    <ul>
                                        <li>
                                            {_s(ctx, "aperobox")} ({p(_s(ctx, "aperobox_prijs"))} voor{" "}
                                            {_s(ctx, "aperobox_personen")})
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
