import React, { useEffect, useState } from "react";
import { StringsLoader as _s } from "../../App";

export default function Overview({ ctx, values, errors, aperoboxProducts }) {
  let menuTwoTotal = values.menuTwoMeat + values.menuTwoVeggie;
  let menuOneTotal = values.menuOneMeat + values.menuOneVeggie;
  const [total, setTotal] = useState(0);
  const [leeggoed, setLeeggoed] = useState(0);
  const [pickedAperoboxProducts, setPickedAperoboxProducts] = useState([]);
  const [pickedAperoboxPrices, setPickedAperoboxPrices] = useState(0);

  useEffect(() => {
    const _subtotal =
      menuOneTotal * _s(ctx, "menu_1_prijs") +
      menuTwoTotal * _s(ctx, "menu_2_prijs") +
      values.mainOne *
      _s(ctx, "hoofdgerecht_1_portie") *
      _s(ctx, "hoofdgerecht_1_prijs") +
      values.mainTwo *
      _s(ctx, "hoofdgerecht_2_portie") *
      _s(ctx, "hoofdgerecht_2_prijs") +
      values.mainThree *
      _s(ctx, "hoofdgerecht_3_portie") *
      _s(ctx, "hoofdgerecht_3_prijs") +
      values.mainFour *
      _s(ctx, "hoofdgerecht_4_portie") *
      _s(ctx, "hoofdgerecht_4_prijs") +
      values.mainFive *
      _s(ctx, "hoofdgerecht_5_portie") *
      _s(ctx, "hoofdgerecht_5_prijs") +
        values.mainSix *
        _s(ctx, "hoofdgerecht_6_portie") *
        _s(ctx, "hoofdgerecht_6_prijs") +
      values.apero * _s(ctx, "aperobox_prijs") +
      pickedAperoboxPrices;

    const _leeggoed =
      Math.floor(_subtotal / _s(ctx, "inklapbox_inhoud") + 1) *
      _s(ctx, "inklapbox_prijs");

    setLeeggoed(_leeggoed);

    setTotal(() => {
      return _subtotal + _leeggoed;
    });
  }, [values, menuOneTotal, menuTwoTotal, ctx, pickedAperoboxPrices]);

  useEffect(() => {
    const pickedProducts = aperoboxProducts
      .filter((item) => values[`aperobox_${item.id}`] > 0)
      .map((product) => {
        product.quantity = values[`aperobox_${product.id}`];
        return product;
      });
    setPickedAperoboxProducts(pickedProducts);
    setPickedAperoboxPrices(
      pickedProducts.reduce((prev, cur) => {
        return prev + cur.price * cur.quantity;
      }, 0)
    );
  }, [aperoboxProducts, values]);

  return (
    <div className="overview-wrapper">
      <div className="client">
        <h3>Gegevens</h3>
        <div className="row">
          {values.firstName && (
            <p>
              Voornaam: <span>{values.firstName}</span>
            </p>
          )}
          {values.lastName && (
            <p>
              Naam: <span>{values.lastName}</span>
            </p>
          )}
          {values.email && (
            <p>
              Email: <span>{values.email}</span>
            </p>
          )}
          {values.phone && (
            <p>
              Telefoonnummer: <span>{values.phone}</span>
            </p>
          )}
        </div>
        <div className="row">
          {values.date && values.moment && (
            <p>
              Afhalen:{" "}
              <span>
                {values.date} om {values.moment} te {values.location}
              </span>
            </p>
          )}
        </div>
        <div className="row">
          <h3>Totaal</h3>
          <p>
            Leeggoed: <span>€ {leeggoed.toLocaleString()}</span>
          </p>
          <p>
            Totaalbedrag: <span>€ {total.toLocaleString()}</span>
          </p>
        </div>
      </div>
      <div className="order">
        <h3>Bestelling</h3>
        <div className="menu">
          {menuOneTotal > 0 && (
            <div className="menuOneOrder">
              <h4>{_s(ctx, "menu_1")}</h4>
              <p>
                Totaal: {menuOneTotal}{" "}
                {menuOneTotal > 1 ? "personen" : "persoon"}
              </p>
              {values.menuOneMeat > 0 && (
                <p>
                  Vlees: {values.menuOneMeat}{" "}
                  {values.menuOneMeat > 1 ? "personen" : "persoon"}
                </p>
              )}
              {values.menuOneVeggie > 0 && (
                <p>
                  Vegetarisch: {values.menuOneVeggie}{" "}
                  {values.menuOneVeggie > 1 ? "personen" : "persoon"}
                </p>
              )}
            </div>
          )}
          {menuTwoTotal > 0 && (
            <div className="menuTwoOrder">
              <h4>{_s(ctx, "menu_2")}</h4>
              <p>
                Totaal: {menuTwoTotal}{" "}
                {menuTwoTotal > 1 ? "personen" : "persoon"}
              </p>
              {values.menuTwoMeat > 0 && (
                <p>
                  Vlees: {values.menuTwoMeat}{" "}
                  {values.menuTwoMeat > 1 ? "personen" : "persoon"}
                </p>
              )}
              {values.menuTwoVeggie > 0 && (
                <p>
                  Vegetarisch: {values.menuTwoVeggie}{" "}
                  {values.menuTwoVeggie > 1 ? "personen" : "persoon"}
                </p>
              )}
            </div>
          )}
        </div>
        {values.mainOne > 0 && (
          <div className="mainOneOrder">
            <h4>{_s(ctx, "hoofdgerecht_1_kort")}</h4>
            <p>
              Totaal: {values.mainOne}{" "}
              {_s(ctx, "hoofdgerecht_1_portie") > 1
                ? `x ${_s(ctx, "hoofdgerecht_1_portie")} personen`
                : values.mainOne > 1
                  ? "personen"
                  : "persoon"}
            </p>
          </div>
        )}
        {values.mainTwo > 0 && (
          <div className="mainTwoOrder">
            <h4>{_s(ctx, "hoofdgerecht_2_kort")}</h4>
            <p>
              Totaal: {values.mainTwo}{" "}
              {_s(ctx, "hoofdgerecht_2_portie") > 1
                ? `x ${_s(ctx, "hoofdgerecht_2_portie")} personen`
                : values.mainTwo > 1
                  ? "personen"
                  : "persoon"}
            </p>
          </div>
        )}
        {values.mainThree > 0 && (
          <div className="mainThreeOrder">
            <h4>{_s(ctx, "hoofdgerecht_3_kort")}</h4>
            <p>
              Totaal: {values.mainThree}{" "}
              {_s(ctx, "hoofdgerecht_3_portie") > 1
                ? `x ${_s(ctx, "hoofdgerecht_3_portie")} personen`
                : values.mainThree > 1
                  ? "personen"
                  : "persoon"}
            </p>
          </div>
        )}
        {values.mainFour > 0 && (
          <div className="mainFourOrder">
            <h4>{_s(ctx, "hoofdgerecht_4_kort")}</h4>
            <p>
              Totaal: {values.mainFour}{" "}
              {_s(ctx, "hoofdgerecht_4_portie") > 1
                ? `x ${_s(ctx, "hoofdgerecht_4_portie")} personen`
                : values.mainFour > 1
                  ? "personen"
                  : "persoon"}
            </p>
          </div>
        )}
        {values.mainFive > 0 && (
          <div className="mainFiveOrder">
            <h4>{_s(ctx, "hoofdgerecht_5_kort")}</h4>
            <p>
              Totaal: {values.mainFive}{" "}
              {_s(ctx, "hoofdgerecht_5_portie") > 1
                ? `x ${_s(ctx, "hoofdgerecht_5_portie")} personen`
                : values.mainFive > 1
                  ? "personen"
                  : "persoon"}
            </p>
          </div>
        )}
        {values.mainSix > 0 && (
            <div className="mainSixOrder">
              <h4>{_s(ctx, "hoofdgerecht_6_kort")}</h4>
              <p>
                Totaal: {values.mainSix}{" "}
                {_s(ctx, "hoofdgerecht_6_portie") > 1
                    ? `x ${_s(ctx, "hoofdgerecht_6_portie")} personen`
                    : values.mainSix > 1
                        ? "personen"
                        : "persoon"}
              </p>
            </div>
        )}
        {values.apero > 0 && (
          <div className="aperoOrder">
            <h4>{_s(ctx, "aperobox_kort")}</h4>
            <p>
              Totaal: {values.apero} {values.apero > 1 ? "boxen" : "box"}
            </p>
          </div>
        )}
        {!!pickedAperoboxProducts.length && (
          <div className="aperoExtras">
            <h4>Aperitief</h4>
            <ul>
              {pickedAperoboxProducts.map((product) => (
                <li>
                  {product.quantity}x {product.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
