import { Col, Container, Row } from "react-bootstrap";
import thanks from "./../../assets/images/thanks.jpg";

export default function Confirmation({ children, data }) {
  const { order, total, account } = data;
  return (
    <Container className={"confirmation"}>
      <Row>
        <Col sm={12}>
          <img src={thanks} alt="Thank you for your order!" />
          <br />
          <div className="pre">
            Gelieve <strong>vóór 16 december</strong> het totaalbedrag van{" "}
            <strong>€ {total.toLocaleString()}</strong> over te maken naar ons
            rekeningnummer <strong>{account}</strong> met als mededeling{" "}
            <strong>'bestelling {order}'</strong>.
          </div>
          <br />
          <div style={{ textAlign: "center", marginTop: 12, marginBottom: 48 }}>
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
