import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StringsContext, StringsLoader as _s } from "../../App";
import logo from "../../assets/images/logo.jpg";

export default function Header() {
  const ctx = useContext(StringsContext);
  return (
    <div className="header-wrapper">
      <Container>
        <Row>
          <Col>
            <header>
              <img src={logo} alt="louisianna logo" />
              <h1>{_s(ctx, "title")}</h1>
            </header>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
