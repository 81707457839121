export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  date: "",
  moment: "",
  location: "",
  menuOneVeggie: 0,
  menuOneMeat: 0,
  menuTwoVeggie: 0,
  menuTwoMeat: 0,
  mainOne: 0,
  mainTwo: 0,
  mainThree: 0,
  mainFour: 0,
  mainFive: 0,
  mainSix: 0,
  apero: 0,
  box: false,
  notes: "",
  invoicing: false,
  invoicing_notes: "",
};
