import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {Formik, Field, Form as FormikForm} from "formik";
import apero from "../../assets/images/feestaperobox.jpg";
import feestmenu1 from "../../assets/images/feestmenu1.png";
import feestmenu2 from "../../assets/images/feestmenu2.png";


import {StringsContext, StringsLoader as _s} from "../../App";
import {initialValues} from "./formik";
import Overview from "../Overview";
import "whatwg-fetch";

export default function Form({setSubmitted, aperoboxProducts}) {
    const [dates, setDates] = useState([]);
    const [moments, setMoments] = useState([]);
    const [locations, setLocations] = useState([]);
    const ctx = useContext(StringsContext);
    const ppp = (val) =>
        `€ ${val.toLocaleString ? val.toLocaleString() : val} p.p.`;
    const p = (val) => `€ ${val.toLocaleString ? val.toLocaleString() : val}`;
    const [showFields, setShowFields] = useState({
        menu1: false,
        menu1Veggie: false,
        menu2: false,
        menu2Veggie: false,
        mainSix: false,
        mainFive: false,
        mainFour: false,
        mainThree: false,
        mainTwo: false,
        mainOne: false,
        apero: false
    });

    const handleAddClick = (fieldName) => {
        setShowFields((prev) => ({
            ...prev,
            [fieldName]: true,
        }));
    };
    useEffect(() => {
        async function loadMoments() {
            const response = await fetch("/moments");
            const data = await response.json();
            setDates(data.afhaaldata.filter((item) => item.date && item.date !== ""));
            setMoments(data.afhaaluren.filter((item) => item && item !== ""));
            setLocations(data.afhaallocaties.filter((item) => item && item !== ''))
        }

        try {
            loadMoments();
        } catch (err) {
            console.error(err);
        }
    }, []);

    const _dates = ["Selecteer een afhaaldatum", ...dates];

    const _moments = ["Selecteer een afhaalmoment", ...moments];

    const _locations = ["Selecteer een afhaallocatie", ...locations];

    const submitHandler = async (
        data,
        {setSubmitting, resetForm, setErrors}
    ) => {
        setSubmitting(true);
        await window
            .fetch("/submit", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
            .then((res) => res.json())
            .then((data) => {
                setSubmitted(data);
                resetForm();
            })
            .catch((error) => {
                console.error(error);
                setErrors({
                    form: _s(ctx, "foutmelding"),
                });
            });
        setSubmitting(false);
    };

    const validationHandler = (values) => {
        const {
            firstName,
            lastName,
            email,
            phone,
            date,
            moment,
            location,
            menuOneVeggie,
            menuOneMeat,
            menuTwoVeggie,
            menuTwoMeat,
        } = values;

        let errors = {};

        if (firstName.length === 0) {
            errors.firstName = "Dit veld is verplicht";
        }
        if (lastName.length === 0) {
            errors.lastName = "Dit veld is verplicht";
        }
        if (email.length === 0) {
            errors.email = "Dit veld is verplicht";
        }
        if (phone.length === 0) {
            errors.phone = "Dit veld is verplicht";
        }
        if (date.length === 0) {
            errors.date = "Dit veld is verplicht";
        }
        if (moment.length === 0) {
            errors.moment = "Dit veld is verplicht";
        }

        if (location.length === 0) {
            errors.location = "Dit veld is verplicht";
        }

        if (menuOneMeat + menuOneVeggie !== 0 && menuOneMeat + menuOneVeggie < 2) {
            errors.menuOneTotal = "Bestel voor minimum twee personen per menu";
        }

        if (menuTwoMeat + menuTwoVeggie !== 0 && menuTwoMeat + menuTwoVeggie < 2) {
            errors.menuTwoTotal = "Bestel voor minimum twee personen per menu";
        }

        return errors;
    };
    return (
        <div className="form-wrapper">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="title">
                            <h2>{_s(ctx, "bestelformulier")}</h2>
                            <p>{_s(ctx, "bestelformulier_intro")}</p>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <Formik
                            validateOnBlur={false}
                            validateOnChange={false}
                            initialValues={initialValues}
                            validate={validationHandler}
                            onSubmit={submitHandler}
                        >
                            {({isSubmitting, errors, values}) => {
                                return (
                                    <FormikForm>
                                        <div className="row">
                                            <div className="field">
                                                <label htmlFor="firstName">Voornaam</label>
                                                <Field
                                                    name="firstName"
                                                    type="input"
                                                    placeholder="Voornaam"
                                                    required
                                                    className={errors.firstName && "error"}
                                                />
                                                {errors.firstName && (
                                                    <p className="error-msg">{errors.firstName}</p>
                                                )}
                                            </div>
                                            <div className="field">
                                                <label htmlFor="lastName">Achternaam</label>
                                                <Field
                                                    name="lastName"
                                                    type="input"
                                                    placeholder="Achternaam"
                                                    required
                                                    className={errors.lastName && "error"}
                                                />
                                                {errors.lastName && (
                                                    <p className="error-msg">{errors.lastName}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="field">
                                                <label htmlFor="email">Email</label>
                                                <Field
                                                    name="email"
                                                    type="email"
                                                    placeholder="Email"
                                                    required
                                                    className={errors.email && "error"}
                                                />
                                                {errors.email && (
                                                    <p className="error-msg">{errors.email}</p>
                                                )}
                                            </div>
                                            <div className="field">
                                                <label htmlFor="phone">Telefoonnummer</label>
                                                <Field
                                                    name="phone"
                                                    type="text"
                                                    placeholder="Telefoonnummer"
                                                    required
                                                    className={errors.phone && "error"}
                                                />
                                                {errors.phone && (
                                                    <p className="error-msg">{errors.phone}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="field">
                                                <label htmlFor="date">Datum</label>
                                                <Field
                                                    name="date"
                                                    as="select"
                                                    placeholder="Selecteer een datum"
                                                    required
                                                    className={errors.date && "error"}
                                                >
                                                    {_dates.map((date, i) => {
                                                        if (i === 0) {
                                                            return <option key={i} value="" label={date}/>;
                                                        }
                                                        return <option key={i}>{date.date}</option>;
                                                    })}
                                                </Field>
                                                {errors.date && (
                                                    <p className="error-msg">{errors.date}</p>
                                                )}
                                            </div>
                                            <div className="field">
                                                <label htmlFor="moment">Afhaaluur</label>
                                                <Field
                                                    name="moment"
                                                    as="select"
                                                    placeholder="Selecteer een uur"
                                                    required
                                                    className={errors.moment && "error"}
                                                >
                                                    {_moments.map((moment, i) => {
                                                        if (i === 0) {
                                                            return <option key={i} value="" label={moment}/>;
                                                        }
                                                        return <option key={i}>{moment}</option>;
                                                    })}
                                                </Field>
                                                {errors.moment && (
                                                    <p className="error-msg">{errors.moment}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="field">
                                                <label htmlFor="location">Afhaallocatie</label>
                                                <Field
                                                    name="location"
                                                    as="select"
                                                    placeholder="Selecteer een locatie"
                                                    required
                                                    className={errors.location && "error"}
                                                >
                                                    {_locations.map((location, i) => {
                                                        if (i === 0) {
                                                            return <option key={i} value="" label={location}/>;
                                                        }
                                                        return <option key={i}>{location}</option>;
                                                    })}
                                                </Field>
                                                {errors.location && (
                                                    <p className="error-msg">{errors.location}</p>
                                                )}
                                            </div>
                                        </div>
                                        <br/>
                                        <p>{_s(ctx, "afhalen")}</p>

                                        <div className="row title">
                                            <h3>Menu's</h3>
                                        </div>
                                        <div className="row">
                                            <div className="field" style={{width: "100%", textAlign: "center"}}>
                                                <img style={{margin: "auto", height: "40px"}} src={feestmenu1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="row menu" style={{justifyContent: "center"}}>
                                            <div className="row submenu" style={{fontWeight: "500"}}>
                                                <div className="field">
                                                    {_s(ctx, "menu_1_kort")}
                                                    <div className="item">
                                                        {/*{!showFields.menu1 ?
                                                            <a className="addButton"
                                                               href="#"
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   handleAddClick("menu1");
                                                               }}
                                                            >
                                                                Toevoegen
                                                            </a> :*/} <div><Field
                                                                name="menuOneMeat"
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                min="0"
                                                                className={errors.menuOneMeat ? "error" : ""}
                                                            ></Field> personen</div>
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    {_s(ctx, "veggie_menu_kort")}
                                                    <div className="item">
                                                        {/*{!showFields.menu1Veggie ?
                                                            <a className="addButton"
                                                               href="#"
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   handleAddClick("menu1Veggie");
                                                               }}
                                                            >
                                                                Toevoegen
                                                            </a> :*/} <div><Field
                                                                name="menuOneVeggie"
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                min="0"
                                                                className={errors.menuOneVeggie ? "error" : ""}
                                                            ></Field> personen</div>
                                                        <label htmlFor="menuOneVeggie">

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field" style={{textAlign: "center", marginTop: "1rem"}}>
                                                <p>
                                                    Totaal aantal personen:{" "}
                                                    <b>{values.menuOneVeggie + values.menuOneMeat}</b>
                                                    <br/>
                                                    <small>
                                                        <em>
                                                            (een menu is pas bestelbaar vanaf 2 personen)
                                                        </em>
                                                    </small>
                                                </p>
                                                {errors.menuOneTotal && (
                                                    <p className="error-msg">{errors.menuOneTotal}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="field" style={{width: "100%", textAlign: "center"}}>
                                                <img style={{height: "40px"}} src={feestmenu2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="row menu" style={{justifyContent: "center"}}>
                                            <div className="row submenu" style={{fontWeight: "500"}}>
                                                <div className="field">
                                                    <label htmlFor="menuTwoMeat">
                                                        {_s(ctx, "menu_2_kort")}
                                                    </label>
                                                    <div className="item">
                                                        {/*{!showFields.menu2 ?
                                                            <a className="addButton"
                                                               href="#"
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   handleAddClick("menu2");
                                                               }}
                                                            >
                                                                Toevoegen
                                                            </a> :*/} <div><Field
                                                                name="menuTwoMeat"
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                min="0"
                                                                className={errors.menuTwoMeat ? "error" : ""}
                                                            ></Field> personen </div>

                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <label htmlFor="menuTwoVeggie">
                                                        {_s(ctx, "veggie_menu_kort")}
                                                    </label>
                                                    <div className="item">
                                                        {/*{!showFields.menu2Veggie ?
                                                            <a className="addButton"
                                                               href="#"
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   handleAddClick("menu2Veggie");
                                                               }}
                                                            >
                                                                Toevoegen
                                                            </a> :*/} <div><Field
                                                                name="menuTwoVeggie"
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                min="0"
                                                                className={errors.menuTwoVeggie ? "error" : ""}
                                                            ></Field> personen</div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field" style={{textAlign: "center", marginTop: "1rem"}}>
                                            <p>
                                                    Totaal aantal personen:{" "}
                                                    <b>{values.menuTwoMeat + values.menuTwoVeggie}</b>
                                                    <br/>
                                                    <small>
                                                        <em>
                                                            (een menu is pas bestelbaar vanaf 2 personen)
                                                        </em>
                                                    </small>
                                                </p>
                                                {errors.menuTwoTotal && (
                                                    <p className="error-msg">{errors.menuTwoTotal}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row title">
                                            <h3>{_s(ctx, "hoofdgerecht_label")}</h3>
                                        </div>
                                        <div className="row main compact">
                                            <div className="field">
                                                <label htmlFor="mainOne">
                                                    <div>{_s(ctx, "hoofdgerecht_1")}</div>
                                                    <b>{ppp(_s(ctx, "hoofdgerecht_1_prijs"))}</b>
                                                </label>
                                                {/*{!showFields.mainOne ?
                                                    <a className="addButton"
                                                       href="#"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           handleAddClick("mainOne");
                                                       }}
                                                    >
                                                        Toevoegen
                                                    </a> :*/} <div><Field
                                                        name="mainOne"
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        min="0"
                                                        className={errors.mainOne ? "error" : ""}
                                                    />{" "}
                                                        {_s(ctx, "hoofdgerecht_1_portie") > 1
                                                            ? `x ${_s(ctx, "hoofdgerecht_1_portie")} personen`
                                                            : `personen`}
                                                        {errors.mainOne && (
                                                            <p className="error-msg">{errors.mainOne}</p>
                                                        )}</div>
                                            </div>
                                            <div className="field">
                                                <label htmlFor="mainTwo">
                                                    <div>{_s(ctx, "hoofdgerecht_2")}</div>
                                                    <b>{ppp(_s(ctx, "hoofdgerecht_2_prijs"))}</b>
                                                </label>
                                                {/*{!showFields.mainTwo ?
                                                    <a className="addButton"
                                                       href="#"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           handleAddClick("mainTwo");
                                                       }}
                                                    >
                                                        Toevoegen
                                                    </a> :*/} <div><Field
                                                        name="mainTwo"
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        min="0"
                                                        className={errors.mainTwo ? "error" : ""}
                                                    />{" "}
                                                        personen
                                                        {errors.mainTwo && (
                                                            <p className="error-msg">{errors.mainTwo}</p>
                                                        )}</div>
                                            </div>

                                            <div className="field">
                                                <label htmlFor="mainThree">
                                                    <div>{_s(ctx, "hoofdgerecht_3")}</div>
                                                    <b>{ppp(_s(ctx, "hoofdgerecht_3_prijs"))}</b>
                                                </label>
                                                {/*{!showFields.mainThree ?
                                                    <a className="addButton"
                                                       href="#"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           handleAddClick("mainThree");
                                                       }}
                                                    >
                                                        Toevoegen
                                                    </a> :*/} <div><Field
                                                        name="mainThree"
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        min="0"
                                                        className={errors.mainThree ? "error" : ""}
                                                    />{" "}
                                                        personen
                                                        {errors.mainThree && (
                                                            <p className="error-msg">{errors.mainThree}</p>
                                                        )}</div>
                                            </div>
                                            <div className="field">
                                                <label htmlFor="mainFour">
                                                    <div>{_s(ctx, "hoofdgerecht_4")}</div>
                                                    <b>{ppp(_s(ctx, "hoofdgerecht_4_prijs"))}</b>
                                                </label>
                                                {/*{!showFields.mainFour ?
                                                    <a className="addButton"
                                                       href="#"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           handleAddClick("mainFour");
                                                       }}
                                                    >
                                                        Toevoegen
                                                    </a> :*/} <div><Field
                                                        name="mainFour"
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        min="0"
                                                        className={errors.mainFour ? "error" : ""}
                                                    />{" "}
                                                        x 2 personen
                                                        {errors.mainFour && (
                                                            <p className="error-msg">{errors.mainFour}</p>
                                                        )}</div>
                                            </div>

                                            <div className="field">
                                                <label htmlFor="mainFive">
                                                    <div>{_s(ctx, "hoofdgerecht_5")}</div>
                                                    <b>{ppp(_s(ctx, "hoofdgerecht_5_prijs"))}</b>
                                                </label>
                                                {/*{!showFields.mainFive ?
                                                    <a className="addButton"
                                                       href="#"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           handleAddClick("mainFive");
                                                       }}
                                                    >
                                                        Toevoegen
                                                    </a> :*/} <div><Field
                                                        name="mainFive"
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        min="0"
                                                        className={errors.mainFive ? "error" : ""}
                                                    />{" "}
                                                        personen
                                                        {errors.mainFive && (
                                                            <p className="error-msg">{errors.mainFive}</p>
                                                        )}</div>
                                            </div>
                                            <div className="field">
                                                <label htmlFor="mainSix">
                                                    <div>{_s(ctx, "hoofdgerecht_6")}</div>
                                                    <b>{ppp(_s(ctx, "hoofdgerecht_6_prijs"))}</b>
                                                </label>
                                                {/*{!showFields.mainSix ?
                                                    <a className="addButton"
                                                       href="#"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           handleAddClick("mainSix");
                                                       }}
                                                    >
                                                        Toevoegen
                                                    </a> :*/}
                                                    <div>
                                                        <Field
                                                            name="mainSix"
                                                            type="number"
                                                            onWheel={(e) => e.target.blur()}
                                                            min="0"
                                                            className={errors.mainSix ? "error" : ""}
                                                        />{" "}
                                                        x 2 personen
                                                        {errors.mainSix && (
                                                            <p className="error-msg">{errors.mainSix}</p>
                                                        )}</div>
                                            </div>
                                        </div>
                                        <div className="row title">
                                            <h3>{_s(ctx, "aperobox_label")}</h3>
                                        </div>
                                        <div className="row main ">
                                            <div className="field fieldApero" style={{display: "flex"}}>
                                                <img style={{width: "27%", marginRight: "1rem"}} src={apero} alt=""/>
                                                <div>
                                                    <label htmlFor="apero">
                                                        <div>{_s(ctx, "aperobox")}</div>
                                                        <b>{p(_s(ctx, "aperobox_prijs"))} per box</b>
                                                    </label>
                                                    {!showFields.apero ?
                                                        <a className="addButton"
                                                           href="#"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               handleAddClick("apero");
                                                           }}
                                                        >
                                                            Toevoegen
                                                        </a> :
                                                        <div><Field
                                                            name="apero"
                                                            type="number"
                                                            onWheel={(e) => e.target.blur()}
                                                            min="0"
                                                        />{" "}
                                                            boxen </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row title">
                                            <h3>{_s(ctx, "aperobox_extra")}</h3>
                                        </div>
                                        <div className="suggestions main  row">
                                            {aperoboxProducts.map((product) => {
                                                return (
                                                    <div className="field row" style={{marginTop:"0.1rem", marginBottom:"0.1rem", padding:"0.1rem 0"}}>
                                                        <label htmlFor="apero" className="row w-75" style={{justifyContent: "unset", height:"42px"}}>
                                                            <div style={{width: "60%",marginTop: "auto", marginBottom: "auto"}}>{product.name}</div>
                                                            <b style={{padding: 0, marginTop: "auto", marginBottom: "auto"}}>{p(product.price)} per stuk</b>
                                                        </label>

                                                        {!showFields[product.id] ?
                                                            <a className="addButton"
                                                               href="#"
                                                               style={{marginTop:"auto", marginBottom:"auto", padding:"0.1rem 0"}}
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   handleAddClick(product.id);
                                                               }}
                                                            > Toevoegen
                                                            </a> :
                                                            <div style={{display: "flex", justifyContent: "end", alignItems: "center"}}><Field
                                                                name={`aperobox_${product.id}`}
                                                                type="number"
                                                                onWheel={(e) => e.target.blur()}
                                                                min="0"
                                                            />&nbsp;
                                                                stuks
                                                            </div>}


                                                    </div>
                                                );

                                            })}
                                        </div>
                                        <div className="row main">
                                            <div className="field">
                                                <label>{_s(ctx, "inklapbox")}</label>
                                                {_s(ctx, "inklapbox_info")}
                                            </div>
                                        </div>
                                        <Overview
                                            ctx={ctx}
                                            aperoboxProducts={aperoboxProducts}
                                            values={values}
                                            errors={errors}
                                        />
                                        <div className="row main">
                                            <div className="field">
                                                <label htmlFor="notes">
                                                    <div>{_s(ctx, "opmerkingen_label")}</div>
                                                </label>
                                                <Field
                                                    style={{
                                                        display: "block",
                                                        width: "100%",
                                                        padding: "0.5rem",
                                                    }}
                                                    name="notes"
                                                    component="textarea"
                                                    placeholder={_s(ctx, "opmerkingen_placeholder")}
                                                />
                                            </div>
                                        </div>
                                        <div className="row main">
                                            <div className="field">
                                                <label>
                                                    <Field name="invoicing" type="checkbox"/>{" "}
                                                    {_s(ctx, "facturatie_checkbox")}
                                                </label>
                                                {values.invoicing && (
                                                    <Field
                                                        style={{
                                                            display: "block",
                                                            width: "100%",
                                                            padding: "0.5rem",
                                                        }}
                                                        name="invoicing_notes"
                                                        component="textarea"
                                                        placeholder={_s(ctx, "facturatie_placeholder")}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="errors row">
                                            {Object.keys(errors).length ? (
                                                <p className="error-msg">
                                                    {errors.form ? errors.form : _s(ctx, "form_errors")}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className="row">
                                            <button style={{color: '#FFF'}} type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? (
                                                    <Spinner animation="border" role="status" size="sm">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                ) : (
                                                    _s(ctx, "bestelling_plaatsen")
                                                )}
                                            </button>
                                        </div>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </div>
    )
        ;
}
